
import CloudFun, { Condition, defineComponent, Operator, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import formatDate from 'xe-utils/toDateString'

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Ordinal' },
      { field: 'Published' },
    ]

    const gridOptions: GridOptions = {
      stripe: false,
      title: '類別',
      rowId: "Id",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: { sheetName: '類別清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '類別清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [{
        field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true, treeNode: true,
        editRender: { name: '$input', immediate: true, attrs: { type: 'text' } },
        filters: [{ checked: false, label: '包含' }],
        slots: { filter: 'filter-name' }
      },
      { field: 'CreatedTime', title: '排序', showHeaderOverflow: true, showOverflow: true, align: "right", width: 120, sortable: true, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : "" },
      // { field: 'Ordinal', title: '排序', showHeaderOverflow: true, showOverflow: true, align: "right", width: 100, sortable: true },
      {
        field: "Published", title: "已發佈", showHeaderOverflow: true, showOverflow: true, align: "center", width: 100, sortable: true, resizable: false,
        cellRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否', disabled: true } }
      },
      ],
      decideRowOperable: (row, operation) => row.DataMode !== 4 || operation === 'read',
      promises: {
        query: model ? (params) => new Promise((resolve) => {
          params.sortings = params.sortings || [];
          params.sortings.push({ column: 'CreatedTime', order: 1 });
          params.condition = params.condition || new Condition();
          params.condition.and('ParentId', Operator.IsNull);
          model.dispatch('category/query', params).then(rep => {
            rep.data.forEach((item: any) => {
              item.Children = item.Children.sort((a: any, b: any) => b.Id - a.Id);
            })
            resolve(rep);
          });
        }) : undefined,
        queryAll: model ? () => model.dispatch('category/query') : undefined,
        save: model ? (params) => model.dispatch('category/save', params) : undefined
      },
      modalConfig: { showFooter: true },
      treeConfig: { children: 'Children', expandAll: true },
    }

    const formOptions: VxeFormProps = {
      titleWidth: 60,
      titleAlign: 'right',
      items: [
        {
          field: 'Name',
          title: '名稱',
          span: 24,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        }, {
          field: 'Published',
          title: '已發佈',
          span: 12,
          titleWidth: 60,
          itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] }
        },
        // {
        //   field: 'Ordinal',
        //   title: '排序',
        //   span: 12,
        //   titleWidth: 60,
        //   itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        // },
        {
          field: 'ParentId',
          title: '父類別',
          span: 24,
          titleWidth: 60,
          slots: { default: 'column-parent-id' }
        },
      ],
      rules: {
        Name: [{ type: 'string', required: true }]
      }
    }

    const parentIdSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇分類',
      textField: 'Name',
      valueField: 'Id',
      formatText: async (row: any) => {
        let entity = await model!.dispatch("category/find", row.Id); // eslint-disable-line
        let text = entity.Name
        while (entity.Parent) {
          entity = entity.Parent
          text = `${entity.Name}>${text}`
        }
        return text
      },
      columns: [
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true, treeNode: true }
      ],
      promises: {
        find: (value) => model!.dispatch("category/find", value), // eslint-disable-line
        query: (params) => new Promise((resolve) => {
          params.sortings = params.sortings || [];
          params.sortings.push({ column: 'CreatedTime', order: 1 });
          params.condition = params.condition || new Condition();
          params.condition.and('ParentId', Operator.IsNull);
          model!.dispatch('category/query', params).then(rep => {
            rep.data.forEach((item: any) => {
              item.Children = item.Children.sort((a: any, b: any) => b.Id - a.Id);
            })
            resolve(rep);
          });
        })
      },
      treeConfig: { parent: 'ParentId', children: 'Children', expandAll: true, reserve: true }
    }

    return {
      grid,
      gridOptions,
      formOptions,
      parentIdSelectOptions
    }
  },
  methods: {
    hideBatchDropDown() {
      cash('#batch-dropdown').dropdown('hide')
    },
    onFormSubmit(row: any, callback: any) {
      if (/^row_/.test(row.Id)) delete row.Id;
      console.log(row)
      callback();
    }
  }
})
